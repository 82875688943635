@import '../styles/partials/breakpoints.module';
@import '../styles/partials/variables.module';

.root {
    padding: 2em 0 0 0;
    
    .wrapper {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 900px;

        img {
            width: 150px;
        }

        h1 {
            margin: 0 0 0 -30px;
            
            a {
                display: block;
                outline: none;
                text-indent: -9999em;
                width: 445px;
                height: 172px;
                background-image: url(../images/logotypeSubhead.png);
                background-repeat: no-repeat;
                background-size: 415px 142px;
                background-position: center;
                border: 1px dotted transparent;

                &:hover, &:focus {
                    border-color: $gray-700;
                }
            }
        }

        .navigation {
            ul {
                display: flex;

                li {
                    margin: 0 0 0 2em;

                    a {
                        color: $gray-500;
                        text-decoration: none;
                        padding: 0 0 5px 0;
                        transition: all ease-in .2s;
                        &:hover {
                            color: $white;
                        }
                        &.active {
                            color: $white;
                            border-bottom: 1px solid $white;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(md-down) {
        padding: 2rem 0 0 0;

        .wrapper {
            width: 100%;

            .navToggle {
                align-self: flex-end;
                margin: .5em;
                background: none;
                border: none;
            }

            img {
                width: 100px;
            }

            h1 {
                margin: 0 0 0 -10px;

                a {
                    width: 220px;
                    height: 75px;
                    background-size: 220px 75px;
                }
            }

            .navigation {
                width: 100%;
                background: $gray-900;
                opacity: 0;
                position: absolute;
                top: -1000px;
                transition: all ease-in-out 300ms;
                &.enter {
                    opacity: 1;
                    top: 0;
                }
                &.enterDone {
                    opacity: 1;
                    top: 0;
                }
                ul {
                    flex-direction: column;

                    li {
                        margin: 0;
                        text-align: center;

                        a {
                            display: inline-block;
                            width: 100%;
                            padding: 1em 0;
                            vertical-align: middle;
                            border-top: 1px solid $gray-700;

                            &.active {
                                border: none;
                                background: $gray-600;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import '../styles/partials/breakpoints.module';
@import '../styles/partials/variables.module';

.root {
    hgroup.resultHeader {
        h1 {
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.2rem;
            margin: -.4rem 0 .3rem 0;
        }

        p {
            margin: 0 0 .5rem 0;
            color: $gray-600;
        }
    }

    table {
        width: 100%;
        border-spacing: 0;
        border: none;
        margin: .5rem 0 0 0;

        th, td {
            margin: 0;
            padding: 0;
        }

        .itemRoot {
            display: block;
            padding: 1rem;
            margin: .5rem 0;
            background: $yellow-light;
            transition: all ease-in .15s;
            text-decoration: none;

            &:hover, &:active, &:focus {
                background: $yellow;

                .itemWrapper h2, .itemWrapper p {
                    color: $white;
                }
            }

            .itemWrapper {
                width: 900px;
                margin: 0 auto;
                display: flex;
                align-items: center;

                h2 {
                    font-size: 1.3rem;
                    font-weight: 600;
                    line-height: 1.7rem;
                    color: $yellow;
                    margin-bottom: .2rem;
                    transition: all ease-in .15s;
                }

                p {
                    font-size: .8em;
                    color: $gray-600;
                    transition: all ease-in .15s;
                }

                .itemImage {
                    width: 80px;
                    min-width: 80px;
                    height: 80px;
                    min-height: 80px;
                    overflow: hidden;
                    margin: 0 1rem 0 0;
                    background: $white;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    @include breakpoint(md-down) {
        table {
            .itemRoot {
                .itemWrapper {
                    width: 100%;
                    
                    .itemImage {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
        }
    }
}

@import '../styles/partials/breakpoints.module';
@import '../styles/partials/variables.module';

.root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $text-color;
    background: $body-bg;

    .wrapper {
        margin: 0 auto;
        padding: 3em;

        h1 {
            font-size: 15em;
            font-weight: bold;
        }
    }

    @include breakpoint(md-down) {
        .wrapper {
            h1 {
                font-size: 5em;
                font-weight: bold;
            }
        }
    }
}

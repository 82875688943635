@import '../styles/partials/breakpoints.module';
@import '../styles/partials/variables.module';

.root {
    .wrapper {
        margin: 0 auto;
        width: 900px;

        h2 {
            font-size: 20px;
            font-weight: bold;
        }
    }

    @include breakpoint(md-down){
        .wrapper {
            width: 100%;
        }
    }
}

@import '../styles/partials/breakpoints.module';
@import '../styles/partials/variables.module';

.root {
    .headerRoot {
        background: $yellow-light;
        margin: 1rem 0 2.5rem 0;

        .headerWrapper {
            width: 900px;
            margin: 0 auto;
            padding: 2.5rem 0;
            display: flex;
            align-items: center;

            .headerImage {
                width: 200px;
                min-width: 200px;
                height: 200px;
                background: $white;
                -webkit-box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.56);
                box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.56);
                margin: 0 2rem 0 0;
        
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .headerMain {
                h2 {
                    font-size: 2.5rem;
                    font-weight: 900;
                    line-height: 3.3rem;
                    width: 100%;
                    margin: 0 0 1rem 0;
                    padding: 0 0 1rem 0;
                    color: $yellow;
                    border-bottom: 1px solid $yellow;
                }

                .headerDetails {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    p {
                        font-size: .8rem;
                        color: $gray-700;
                        margin: 0 1.5rem 1rem 0;
                        min-width: 5rem;
                        max-width: 12rem;

                        &:last-child {
                            margin-right: 0;
                        }

                        span {
                            display: block;
                        }
        
                        strong {
                            display: block;
                            font-size: 1.1rem;
                            color: $yellow;
                        }
                    }
                }
            }
        }
    }

    .contentRoot {
        .contentWrapper {
            width: 900px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            section {
                margin-bottom: 2.5rem;
                max-width: 440px;

                h3 {
                    margin: 0 0 1rem 0;
                    padding: 0 0 .5rem 0;
                    font-size: 1.5rem;
                    font-weight: 900;
                    border-bottom: 1px solid $gray-300;
                }

                figure {
                    margin: 0 0 2rem 0;
                    min-width: 400px;

                    h4 {
                        background-repeat: no-repeat;
                        background-size: 100%;
                        overflow: hidden;
                        text-indent: -9999px;
                    }

                    h4.soundcloud {
                        width: 150px;
                        height: 18px;
                        background-image: url(../images/soundcloud.svg);
                        margin-bottom: .8rem;
                    }

                    h4.spotify {
                        width: 100px;
                        height: 32px;
                        background-image: url(../images/spotify.png);
                        margin-bottom: .5rem;
                    }

                    iframe {
                        border-radius: 5px;
                        width: 400px;
                    }
                }
            }
        }
    }

    .itemError {
        text-align: center;
    }

    @include breakpoint(md-down) {
        .headerRoot {
            margin-bottom: 2rem;
            
            .headerWrapper {
                width: 100%;
                padding: 2.5rem 1.5rem 1.5rem 1.5rem;
                flex-direction: column;

                .headerImage {
                    margin: 0 0 1.5rem 0;
                }
                
                .headerMain {
                    h2 {
                        text-align: center;
                    }

                    .headerDetails {
                        display: inline-block;

                        p {
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .mediaRoot {
            .mediaWrapper {
                width: 100%;
                padding: 0 1.5rem;
                display: block;
            }
        }

        .contentRoot {
            padding: 0 1.5rem;

            .contentWrapper {
                width: 100%;
                flex-direction: column;

                section {
                    figure {
                        min-width: 100%;
                        width: 100%;

                        iframe {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (prefers-color-scheme: dark), (prefers-color-scheme: light) {
    body {
        margin: 0;
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: $font-weight-base;
        line-height: $line-height-base;
        color: $text-color;
        text-align: left;
        background-color: $body-bg;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a {
        color: $text-color;
    }

    small {
        font-size: .7em;
    }
}


@import "../styles/partials/_breakpoints.module.scss";
@import '../styles/partials/variables.module';

.root {
    .wrapper {
        margin: 0 auto;
        width: 900;

        form {
            position: relative;
            margin: 0 auto;
            width: 600px;
            padding: .5rem 1rem 1rem 1rem;
            background-color: $yellow;
            border-radius: 10px;

            .searchInputWrapper {
                display: inline-block;
                margin: 0 .5rem 0 0;
                width: 100%;

                label {
                    font-weight: 600;
                    color: white;
                }

                .searchInput {
                    margin: .5rem 0 0 0;
                    border: none;
                    border-radius: 5px;
                    padding-right: 5.8rem;
                }
            }

            .searchButtonWrapper {
                display: inline-block;
                width: 2.3rem;
                position: absolute; 
                right: 1.5rem;
                top: 2.7rem;

                .searchButton {
                    width: 2.3rem;
                    height: 2.3rem;
                    border-radius: 50%;
                    transition: all ease-in .2s;

                    &:hover {
                        background-color: $yellow-light;
                    }

                    &:focus {
                        background-color: $yellow;
                        color: $white;
                    }

                    &[disabled]:hover {
                        background-color: initial;
                    }
                }
            }

            .clearButtonWrapper {
                display: inline-block;
                width: 2.3rem;
                position: absolute;
                right: 3.8rem;
                top: 2.8rem;

                .clearButton {
                    width: 2.3rem;
                    height: 2.3rem;
                    border-radius: 50%;
                    transition: all ease-in .1s;
                    margin: 0;

                    &:hover {
                        background-color: $yellow-light;
                    }

                    &:focus {
                        background-color: $yellow;
                        color: $white;
                    }
                }
            }
        }
    }

    @include breakpoint(md-down) {
        margin: 0;
        
        .wrapper {
            width: 100%;

            form {
                width: 100%;
                border-radius: 0;
            }
        }
    }
}

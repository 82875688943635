@import '../styles/partials/breakpoints.module';
@import '../styles/partials/variables.module';

.root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    main {
        padding: 1.5rem 0 4rem 0;
    }

    @include breakpoint(md-down) {
    }
}

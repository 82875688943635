@import '../styles/partials/breakpoints.module';
@import '../styles/partials/variables.module';

.root {
    color: $gray-500;
    font-size: .8rem;

    .wrapper {
        margin: 0 auto;
        padding: 3em 0 3.5em 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 900px;

        .logotype {
            display: flex;

            small {
                font-size: .8rem;
            }

            h2 {
                margin: -.1rem .5rem 0 0;

                a {
                    display: block;
                    outline: none;
                    text-indent: -9999px;
                    width: 95px;
                    height: 21px;
                    background-image: url(../images/logotype.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 95px 21px;
                    border: 1px dotted transparent;

                    &:hover, &:focus {
                        border-color: $gray-700;
                    }
                }
            }

        }

        .navigation {
            a {
                color: $gray-700;
                text-decoration: none;
                &:hover {
                    border-bottom: 1px solid $gray-500;
                }
            }

            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    margin: 0 0 0 2.5em;
                }
            }
        }
    }

    @include breakpoint(md-down) {
        .wrapper {
            flex-direction: column;
            padding: 1em 0 1.5em 0;
            width: 100%;

            .logotype {
                margin: 0 0 .5em 0;
            }

            .navigation {
                ul {
                    justify-content: center;

                    li {
                        margin: 0 1em 0 0;
                    }
                }
            }
        }
    }
}

@import "../styles/partials/_breakpoints.module.scss";
@import "../styles/partials/_variables.module.scss";

.root {    
    .wrapper {
        .searchForm {
            margin: 0 0 2.5rem 0;
        }
        .progressIndicator {
            margin: 2rem;
        }
        .error {
            text-align: center;
            margin: 2rem;
        }
    }

    @include breakpoint(md-down) {
        .wrapper {
            .searchForm {
                margin: 0 0 0 0;
            }
        }
    }
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;900&display=swap');

//colors
$white:                   #ffffff !default;
$gray-100:                #f8f9fa !default;
$gray-200:                #e9ecef !default;
$gray-300:                #dee2e6 !default;
$gray-400:                #ced4da !default;
$gray-500:                #adb5bd !default;
$gray-600:                #6c757d !default;
$gray-700:                #495057 !default;
$gray-800:                #343a40 !default;
$gray-900:                #212529 !default;
$black:                   #000000 !default;


$yellow:                  #e1c33e;
$yellow-light:            #fbf7e5;


//errors
$error:                   #ed2024 !default;
$warn:                    #f6eb14 !default;
$ok:                      #69bd45 !default;



//typography
$font-family-sans-serif:  'Noto Sans JP', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:   source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base:        $font-family-sans-serif !default;
$font-size-base:          1rem !default; // Assumes the browser default, typically `16px`
$line-height-base:        1.5 !default;
$font-weight-light:       300 !default;
$font-weight-normal:      400 !default;
$font-weight-strong:      500 !default;
$font-weight-bold:        700 !default;
$font-weight-base:        $font-weight-normal !default;



//main Styles
$body-bg:                 $white !default;
$text-color:              $gray-700 !default;




//export variables
:export {
    //colors
    white: $white;
    gray100: $gray-100;
    gray200: $gray-200;
    gray300: $gray-300;
    gray400: $gray-400;
    gray500: $gray-500;
    gray600: $gray-600;
    gray700: $gray-700;
    gray800: $gray-800;
    gray900: $gray-900;
    black: $black;

    //errors
    error: $error;
    warn: $warn;
    ok: $ok;
}
